<template>
	<el-container>
		<el-main>
			<el-card shadow="never">
				<el-row :gutter="10" class="top_l">
					<el-col :span="6" :xs="24"><el-input v-model="serchIdentity" clearable placeholder="请输入游戏标识"></el-input></el-col>
					<el-col :span="12" :xs="24">
						<el-button type="primary" @click="jouShare">搜索</el-button>
						<el-button type="primary" style="margin-left: 20px;" @click="dialogAdd = true">新增</el-button>
						<el-button type="primary" style="margin-left: 20px;" @click="refreshCache">刷新缓存</el-button>
					</el-col>
				</el-row>
				<!-- table 表格 -->
				<el-table v-loading="serloading" :data="tableData" :header-cell-style="{ background: '#eef1f6' }" border stripe>
					<el-table-column prop="id" label="ID"></el-table-column>
					<el-table-column prop="identity" label="游戏标识"></el-table-column>
					<el-table-column prop="name" label="名称"></el-table-column>
					<el-table-column prop="createTime" label="创建时间"></el-table-column>
					<el-table-column prop="updateTime" label="更新时间"></el-table-column>
					<el-table-column label="操作" align="center">
						<template v-slot="scope">
							<el-button size="small" type="primary" plain @click="clcedit(scope.row)">编辑</el-button>
						</template>
					</el-table-column>
					<template #empty>
						<el-empty :description="emptyText" :image-size="100"></el-empty>
					</template>
				</el-table>
				<!-- 分页 -->
				<div class="pagin">
					<el-pagination
						:small="true"
						v-model:currentPage="currentPage"
						v-model:page-size="pageSize"
						:page-sizes="[10, 20, 50, 100]"
						background
						layout="total, sizes, prev, pager, next, jumper"
						:total="+totals"
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
					/>
				</div>
				<!-- 新增-产品 -->
				<el-dialog v-model="dialogAdd" title="新增" width="50%" :fullscreen="fullscreenshow">
					<el-form :model="ruleForm" :rules="rules" ref="addruleForm" label-width="130px" class="demo-ruleForm">
						<el-row :gutter="20">
							<el-col :span="12" :xs="24">
								<el-form-item label="游戏标识" prop="identity"><el-input placeholder="请输入游戏标识" v-model="ruleForm.identity"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="名称" prop="name"><el-input placeholder="请输入名称" v-model="ruleForm.name"></el-input></el-form-item>
							</el-col>
						</el-row>
					</el-form>
					<template #footer>
						<span class="dialog-footer">
							<el-button type="primary" @click="submitClick('addruleForm')">提交</el-button>
							<el-button @click="resetClick('addruleForm')">重置</el-button>
						</span>
					</template>
				</el-dialog>
				<!-- 修改 -->
				<el-dialog v-model="dialogEdit" title="修改" width="50%" :fullscreen="fullscreenshow">
					<el-form :model="EditruleForm" :rules="rules" ref="EditruleFormRef" label-width="110px" class="demo-ruleForm">
						<el-row :gutter="20">
							<el-col :span="12" :xs="24">
								<el-form-item label="游戏标识" prop="identity"><el-input v-model="EditruleForm.identity"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="名称" prop="name"><el-input v-model="EditruleForm.name"></el-input></el-form-item>
							</el-col>
						</el-row>
					</el-form>
					<template #footer>
						<span class="dialog-footer">
							<el-button type="primary" @click="EditsubmitForm('EditruleFormRef')">提交</el-button>
							<el-button @click="dialogEdit = false">取消</el-button>
						</span>
					</template>
				</el-dialog>
			</el-card>
		</el-main>
	</el-container>
</template>

<script>
export default {
	name: 'gameIdentityInfo',
	data() {
		return {
			fullscreenshow: false,
			emptyText: '暂无数据',
			currentPage: 1,
			pageSize: 10,
			tableData: [],
			totals: null,
			serloading: false,
			productId: '',
			serchIdentity: '',

			dialogAdd: false,
			ruleForm: {
				name: '',
				identity: ''
			},
			rules: {
				identity: [{ required: true, message: '请输入游戏标识', trigger: 'blur' }],
				name: [{ required: true, message: '请输入名称', trigger: 'blur' }]
			},
			// 编辑
			dialogEdit: false,
			EditruleForm: {
				id: '',
				name: '',
				identity: ''
			}
		};
	},
	created() {
		this.productId = this.$TOOL.data.get('DATA_SELECTEED');
		// 获取游戏信息
		this.Cluserlist();

		if (document.body.clientWidth < 768) {
			this.fullscreenshow = true;
		} else {
			this.fullscreenshow = false;
		}
	},
	methods: {
		// 获取游戏信息
		Cluserlist() {
			this.serloading = true;
			this.$HTTP.post(
				'gameIdentityInfo/query',
				{
					productId: this.productId,
					pageNumber: this.currentPage,
					pageSize: this.pageSize,
					identity: this.serchIdentity
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.tableData = response.data.data.records;
							this.totals = response.data.data.total;
							this.serloading = false;
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// 重置
		resetClick(name) {
			this.$refs[name].resetFields();
		},
		// 搜索
		jouShare() {
			this.serloading = true;
			this.currentPage = 1;
			// 获取游戏信息
			this.Cluserlist();
		},
		// 刷新缓存
		refreshCache() {
			this.serloading = true;
			this.$HTTP.post('gameIdentityInfo/refreshCache', {}, response => {
				if (response.status >= 200 && response.status < 300) {
					if (response.data.code == 0) {
						this.serloading = false;
						this.$message.success(response.data.msg);
					} else {
						this.$message.error(response.data.msg);
					}
				} else {
					console.log(response.message);
				}
			});
		},
		// 新增
		submitClick(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.$HTTP.post(
						'gameIdentityInfo/add',
						{
							identity: this.ruleForm.identity,
							name: this.ruleForm.name
						},
						response => {
							if (response.status >= 200 && response.status < 300) {
								if (response.data.code == 0) {
									this.dialogAdd = false;
									this.$message.success(response.data.msg);
									this.resetClick(formName);
									// 获取游戏信息
									this.Cluserlist();
								} else {
									this.$message.error(response.data.msg);
								}
							} else {
								console.log(response.message);
							}
						}
					);
				} else {
					return false;
				}
			});
		},
		// 编辑
		clcedit(row) {
			this.EditruleForm.identity = row.identity;
			this.EditruleForm.name = row.name;
			this.EditruleForm.id = row.id;
			this.dialogEdit = true;
		},
		EditsubmitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.$HTTP.post(
						'gameIdentityInfo/update',
						{
							id: this.EditruleForm.id,
							identity: this.EditruleForm.identity,
							name: this.EditruleForm.name
						},
						response => {
							if (response.status >= 200 && response.status < 300) {
								if (response.data.code == 0) {
									this.dialogEdit = false;
									this.$message.success(response.data.msg);
									// 获取游戏信息
									this.Cluserlist();
								} else {
									this.$message.error(response.data.msg);
								}
							} else {
								console.log(response.message);
							}
						}
					);
				} else {
					return false;
				}
			});
		},
		// 分页
		handleSizeChange(val) {
			this.currentPage = 1;
			this.pageSize = val;
			// 获取游戏信息
			this.Cluserlist();
		},
		handleCurrentChange(val) {
			this.currentPage = val;
			// 获取游戏信息
			this.Cluserlist();
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
